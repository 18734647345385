<div class="top-section">
  <app-overview-harvest-year-selector
    [start]="HarvestYear.HARVEST_YEAR_START"
    [end]="HarvestYear.HARVEST_YEAR_END"
  ></app-overview-harvest-year-selector>
  <app-overview-crop-filter></app-overview-crop-filter>
  <app-product-filter></app-product-filter>
</div>

<app-farm-tasks-overview-table></app-farm-tasks-overview-table>
