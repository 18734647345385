<form [formGroup]="form">
  <div class="as-applied-link-wrapper">
    <h2>{{ 'main.asApplied.metaData.informationAboutFieldTask' | translate }}</h2>
    <div class="input-container">
      <!--  FIELD NUMBER   -->
      <div class="field-number">
        <span class="text">{{ 'main.asApplied.metaData.fieldNumber' | translate }}</span>
        <span class="value">{{ executedTasks.first()?.fieldName }}</span>
      </div>

      <!--  Task   -->
      <div class="field-task">
        <div class="text">{{ 'main.asApplied.metaData.fieldTask' | translate }}</div>
        <div class="value">
          <ng-container *ngIf="taskSuggestions$ | async as selectableTasksGroupedByOperationTypeGroup; else emptySelect">
            <app-select
              class="metadata-select"
              formControlName="metadataTask"
              [noPrefix]="selectableTasksGroupedByOperationTypeGroup.length === 0"
              placeholder="{{
                (selectableTasksGroupedByOperationTypeGroup.length === 0
                  ? 'main.asApplied.metaData.noTaskOnField'
                  : 'main.asApplied.metaData.fieldTask'
                ) | translate
              }}"
            >
              <app-select-option-group
                *ngFor="let operationTypeGroupTasks of selectableTasksGroupedByOperationTypeGroup"
                [label]="operationTypeGroupTasks.operationTypeGroupName"
              >
                <app-select-option *ngFor="let selectableTask of operationTypeGroupTasks.tasks" [value]="selectableTask">
                  <!--   Show tooltip if the text is more than 35 characters   -->
                  <app-tooltip
                    *ngIf="selectableTask.text.length > 35; else noTooltip"
                    [tooltipText]="selectableTask.text"
                    [delayType]="'long'"
                  >
                    {{ selectableTask.text }}
                  </app-tooltip>
                  <ng-template #noTooltip>
                    {{ selectableTask.text }}
                  </ng-template>
                </app-select-option>
              </app-select-option-group>
            </app-select>
          </ng-container>

          <ng-template #emptySelect>
            <app-select
              class="metadata-select"
              [disabled]="true"
              placeholder="{{ 'main.asApplied.metaData.fieldTask' | translate }}"
            ></app-select>
          </ng-template>
        </div>
      </div>

      <!--  DATE  -->
      <div class="field-date">
        <span class="text">{{ 'main.asApplied.metaData.date' | translate }}</span>
        <span class="value">{{ executedTasks.first()?.date | dateFormat }}</span>
      </div>
    </div>
  </div>

  <div class="button-wrapper">
    <button mat-button [disabled]="form.invalid" (click)="saveClicked()">
      <ng-container>
        <div class="button-content">
          <mat-icon class="material-symbols-outlined">save</mat-icon>
          <span>{{ 'Gem' | translate }}</span>
        </div>
      </ng-container>
    </button>
  </div>
</form>
