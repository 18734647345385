import { NavigationItem } from './navigation-item';

export const navigationItemsContainer: Readonly<NavigationItemsContainer> = {
  cultivationJournalSection: new NavigationItem({
    translationString: 'navigation.cultivationJournal',
    items: [],
    route: 'map/cultivation-journal',
    icon: 'field-plan',
    module: 'field_plan',
  }),

  farmTasksOverviewSection: new NavigationItem({
    translationString: 'navigation.farmTasksOverview',
    items: [],
    route: 'overview',
    icon: 'Markanalyse', //! placeholder icon
    module: 'farm_tasks_overview',
    featureToggleKey: 'pla_cropmanager_farm_task_overview',
  }),

  vraSection: new NavigationItem({
    translationString: 'navigation.vra',
    items: [],
    route: 'map/vra',
    icon: 'prescription-maps',
    module: 'vra',
    disabledTranslationString: 'navigation.disabled.vra-required',
  }),

  fieldAnalysisSection: new NavigationItem({
    translationString: 'navigation.fieldAnalysis',
    items: [],
    route: 'map/field-analysis',
    icon: 'field-analysis',
    module: 'field_analysis',
  }),

  prognosesSection: new NavigationItem({
    translationString: 'navigation.prognosis',
    items: [],
    route: 'map/prognosis',
    icon: 'prognosis',
    module: 'prognosis',
    disabledTranslationString: 'navigation.disabled.alerts-or-basic-required',
  }),

  basisLayerSection: new NavigationItem({
    translationString: 'navigation.basislayer',
    items: [],
    route: 'map/basis-layer',
    icon: 'custom-maps',
    module: 'basis_layer',
    disabledTranslationString: 'navigation.disabled.vra-required',
  }),

  hotspotsSection: new NavigationItem({
    translationString: 'navigation.hotspots',
    items: [],
    route: 'map/hotspots',
    icon: 'hotspots',
    module: 'hotspots',
    disabledTranslationString: 'navigation.disabled.basic-required',
  }),

  yieldBenchmarkSection: new NavigationItem({
    translationString: 'navigation.yieldBenchmark',
    items: [],
    route: 'map/yield-benchmark',
    icon: 'Benchmark',
    module: 'yield_benchmark',
  }),

  economySection: new NavigationItem({
    translationString: 'navigation.economy',
    items: [],
    route: 'economy/table',
    icon: 'productioncost',
    module: 'economy',
  }),

  settings: new NavigationItem({
    translationString: 'navigation.settings.dataConnections',
    items: [
      new NavigationItem({
        translationString: 'navigation.settings.dataConnections',
        items: [],
        route: 'settings/connections',
        resource: 'settings_data_connections',
        icon: 'dataconnection',
      }),
      new NavigationItem({
        translationString: 'navigation.datamanagement.fieldTransfer',
        items: [],
        route: 'settings/datamanagement/fields',
        resource: 'settings_transfer',
        icon: 'transfer-fields',
        disabledTranslationString: 'navigation.disabled.basic-required',
      }),
      new NavigationItem({
        translationString: 'navigation.datamanagement.taskTransfer',
        items: [],
        route: 'settings/datamanagement/tasks',
        resource: 'settings_transfer',
        icon: 'transfer-data',
        disabledTranslationString: 'navigation.disabled.basic-required',
      }),
    ],
    route: 'settings',
    icon: 'dataconnection',
    module: 'settings',
  }),
} as const;

export interface NavigationItemsContainer {
  cultivationJournalSection: NavigationItem;
  farmTasksOverviewSection: NavigationItem;
  fieldAnalysisSection: NavigationItem;
  vraSection: NavigationItem;
  prognosesSection: NavigationItem;
  basisLayerSection: NavigationItem;
  hotspotsSection: NavigationItem;
  yieldBenchmarkSection: NavigationItem;
  economySection: NavigationItem;
  settings: NavigationItem;
}
