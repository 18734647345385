import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FieldEntryQuery } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.query';
import { FieldEntryService } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.service';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { FilterCrop } from '@app/shared/map-layer-controls/map-crop-filter-control/crop-filter.store';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { debounceTime, distinctUntilChanged, map, skip, Subject } from 'rxjs';

@Component({
  selector: 'app-overview-crop-filter',
  templateUrl: './overview-crop-filter.component.html',
  styleUrls: ['./overview-crop-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewCropFilterComponent implements OnInit, OnDestroy {
  private cropFilterUpdates = new Subject<FilterCrop[]>();
  private pendingUpdates: FilterCrop[] = [];

  constructor(
    private fb: FormBuilder,
    private _accessControlService: AccessControlService,
    private _harvestYearService: HarvestYearStateService,
    private _farmStateService: FarmStateService,
    private _fieldEntryQuery: FieldEntryQuery,
    private _fieldEntryService: FieldEntryService
  ) {}

  protected cropTotalArea = {
    first: 0.0,
    second: 0.0,
    third: 0.0,
  };

  protected readonly crops$ = this._fieldEntryQuery.crops$;

  protected readonly filterStateTranslateKey$ = this._fieldEntryQuery.cropFilter$.pipe(
    map((cropFilter) => {
      if (cropFilter.length === 0) {
        return 'farm-tasks-overview.filter-state.none';
      }
      if (cropFilter.length === 1) {
        return cropFilter[0].cropName;
      }
      if (this.crops.controls.some((control) => !control.value.enabled)) {
        return 'farm-tasks-overview.filter-state.some';
      }
      return 'farm-tasks-overview.filter-state.all';
    })
  );

  protected form!: FormGroup;

  private _subs = new SubscriptionArray();
  protected readonly hasAccess$ = this._accessControlService.policies$.pipe(
    map((policy) => {
      return policy.includes('basic');
    })
  );

  ngOnInit() {
    this._subs.add(
      this.cropFilterUpdates.pipe(debounceTime(1000)).subscribe((updates) => {
        updates.forEach((crop) => {
          this._fieldEntryService.updateCropFilter({
            cropName: crop.name,
            term: crop.succession,
          });
        });
        this.pendingUpdates = [];
      })
    );

    this._subs.add(
      this.crops$.subscribe((crops) => {
        this.form = this.fb.group({
          crops: this.fb.array(
            crops.map((crop) => {
              return this.createGroup(crop);
            })
          ),
        });
        this.calculcateTotalAreaCrop();
        if (crops.every((crop) => crop.enabled)) {
          this.selectAll();
        }
      })
    );

    this._subs.add(this._farmStateService.selectedFarms$.pipe(skip(1), distinctUntilChanged()).subscribe((_) => this.resetCropFilter()));
    this._subs.add(this._harvestYearService.harvestYear$.pipe(skip(1), distinctUntilChanged()).subscribe((_) => this.resetCropFilter()));
  }

  ngOnDestroy() {
    this.cropFilterUpdates.complete();
    this._subs.unsubscribe();
  }

  private createGroup(crop: FilterCrop) {
    const group = this.fb.group({
      id: [crop.id],
      name: [crop.name],
      color: [crop.color],
      totalArea: [crop.totalArea],
      enabled: [crop.enabled],
      fieldIds: [crop.fieldIds],
      succession: [crop.succession],
    });
    return group;
  }

  protected selectAll() {
    this.crops.controls.forEach((control) => {
      control.patchValue({ enabled: true });
    });
    this.resetCropFilter();
    this.calculcateTotalAreaCrop();
  }

  protected deselectAll() {
    this.crops.controls.forEach((control) => {
      control.patchValue({ enabled: false });
    });
    this.clearCropFilter();
    this.cropTotalArea = {
      first: 0.0,
      second: 0.0,
      third: 0.0,
    };
  }

  protected isAllSelected() {
    return this.crops.controls.every((control) => {
      const enabled = control.get('enabled')?.value;
      return enabled;
    });
  }

  protected toggleCrop(crop: AbstractControl) {
    const cropData: FilterCrop = {
      id: crop.get('id')?.value,
      fieldIds: crop.get('fieldIds')?.value,
      color: crop.get('color')?.value,
      name: crop.get('name')?.value,
      totalArea: crop.get('totalArea')?.value,
      succession: crop.get('succession')?.value,
      enabled: crop.get('enabled')?.value,
    };

    // Find if this crop is already in pending updates
    const existingIndex = this.pendingUpdates.findIndex(
      (update) => update.name === cropData.name && update.succession === cropData.succession
    );

    if (existingIndex !== -1) {
      // Remove the existing update if found
      this.pendingUpdates.splice(existingIndex, 1);
    } else {
      // Add new update if not found
      this.pendingUpdates.push(cropData);
    }

    // Only trigger updates if there are pending changes
    if (this.pendingUpdates.length > 0) {
      this.cropFilterUpdates.next(this.pendingUpdates);
    }

    this.calculcateTotalAreaCrop();
  }

  private calculcateTotalAreaCrop() {
    const totalAreaFirst = this.firstTermCrops.controls
      .filter((cropsControl) => cropsControl.get('enabled')?.value === true)
      .map((cropsControl) => cropsControl.get('totalArea')?.value)
      .reduce((acc, area) => acc + (area || 0), 0);
    const totalAreaSecond = this.secondTermCrops.controls
      .filter((cropsControl) => cropsControl.get('enabled')?.value === true)
      .map((cropsControl) => cropsControl.get('totalArea')?.value)
      .reduce((acc, area) => acc + (area || 0), 0);
    const totalAreaThird = this.thirdTermCrops.controls
      .filter((cropsControl) => cropsControl.get('enabled')?.value === true)
      .map((cropsControl) => cropsControl.get('totalArea')?.value)
      .reduce((acc, area) => acc + (area || 0), 0);
    this.cropTotalArea = this.cropTotalArea = {
      first: totalAreaFirst,
      second: totalAreaSecond,
      third: totalAreaThird,
    };
  }

  private resetCropFilter() {
    this._fieldEntryService.resetCropFilter();
  }

  private clearCropFilter() {
    this._fieldEntryService.clearCropFilter();
  }

  get crops(): FormArray {
    return this.form.get('crops') as FormArray;
  }

  get firstTermCrops(): FormArray {
    const filteredControls = this.crops.controls
      .filter((control) => control.get('succession')?.value === 1)
      .sort((a, b) => {
        const areaA = a.get('totalArea')?.value;
        const areaB = b.get('totalArea')?.value;
        return areaB - areaA;
      });
    return new FormArray(filteredControls);
  }

  get secondTermCrops(): FormArray {
    const filteredControls = this.crops.controls
      .filter((control) => control.get('succession')?.value === 2)
      .sort((a, b) => {
        const areaA = a.get('totalArea')?.value;
        const areaB = b.get('totalArea')?.value;
        return areaB - areaA;
      });
    return new FormArray(filteredControls);
  }

  get thirdTermCrops(): FormArray {
    const filteredControls = this.crops.controls
      .filter((control) => control.get('succession')?.value === 3)
      .sort((a, b) => {
        const areaA = a.get('totalArea')?.value;
        const areaB = b.get('totalArea')?.value;
        return areaB - areaA;
      });
    return new FormArray(filteredControls);
  }
}
