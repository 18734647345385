<div class="task-detail-card-container">
  <mat-card appearance="outlined" class="task-card full-card">
    <div class="task-card-content">
      <div class="operation-type-chip" [ngStyle]="{ 'background-color': operationTypeColor }">
        <span class="title">{{ operationTypeString | translate }}</span>
      </div>
      <div class="task-complete-chip" [ngStyle]="{ 'background-color': operationStatusColor }">
        <span>{{ operationStatusString | translate }}</span>
      </div>
      <div class="task-products">
        <span class="product-line" *ngFor="let product of taskEntry.products">
          <span class="name"> {{ product.label ?? '-' }}</span>
          <span class="unit"> {{ product.avgQuantity ?? '-' | unit: product.unit }} {{ product.unit ?? '' }}/ha</span>
        </span>
      </div>
    </div>
  </mat-card>
</div>
