<div class="field-card-container">
  <mat-card
    appearance="outlined"
    class="field-card"
    [matTooltip]="fieldToolTip"
    [matTooltipShowDelay]="500"
    matTooltipClass="field-card-tooltip"
  >
    <div class="field-card-content">
      <div class="field-card-content-icon">
        <div class="icon-wrapper">
          <ng-container *ngIf="fieldEntry.polygon; else noFieldPolygon">
            <app-geojson-icon
              *ngIf="fieldEntry.polygon"
              [size]="30"
              class="icon-content"
              [geojson]="polygon"
              [color]="fieldEntry.cropColor ?? '#ffffff'"
            ></app-geojson-icon>
          </ng-container>

          <ng-template #noFieldPolygon>
            <div class="no-polygon-placeholder">
              <img src="assets\images\icons\no-field-polygon.png" />
            </div>
          </ng-template>
        </div>
      </div>
      <div class="field-card-content-info">
        <span class="field-card-content-info-name"> {{ fieldEntry.label ?? '-' }}</span>
        <span class="field-card-content-info-crop"> {{ fieldEntry.cropName ?? '-' | truncate: [13, '..'] }}</span>
        <span class="field-card-content-info-area"> {{ fieldEntry.area ? (fieldEntry.area | unit: 'ha') : '-' }} ha</span>
      </div>
    </div>
  </mat-card>
</div>
