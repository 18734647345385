import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { TaskEntry } from '@app/farm-tasks-overview/class/task-entry';
import { Status } from '@app/farm-tasks-overview/enums/status.enum';
import { ChipUtil } from '@app/farm-tasks-overview/util/chip-util';
import { first } from 'rxjs';

@Component({
  selector: 'app-minimal-task-card',
  templateUrl: './minimal-task-card.component.html',
  styleUrls: ['minimal-task-card.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('100ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [animate('0ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MinimalTaskCardComponent implements OnInit {
  @Input() public taskEntries!: TaskEntry[];
  private DELAY = 125;
  private isMobile = false;

  protected isMultipleTasks = false;
  protected firstTaskMultipleProducts = false;
  protected operationTypeColor = '';
  protected operationTypeString = '';
  protected operationStatusColor = '';
  protected operationStatusString = '';
  protected additionalTaskCount = 0;
  protected additionalProductCount = 0;
  protected additionalTasks: TaskEntry[] = [];
  protected firstTask!: TaskEntry;
  constructor(
    private cdr: ChangeDetectorRef,
    private screensizeService: ScreenSizeService
  ) {}
  ngOnInit(): void {
    this.screensizeService
      .isPortable()
      .pipe(first())
      .subscribe((isPortable) => {
        this.isMobile = false;
      });
    this.firstTask = this.taskEntries.first()!;
    this.additionalTasks = this.taskEntries;
    this.isMultipleTasks = this.taskEntries.length > 1;
    this.additionalTaskCount = this.taskEntries.length - 1;
    this.additionalProductCount = this.taskEntries.first()!.products.length - 1;
    this.firstTaskMultipleProducts = this.taskEntries.first()!.products.length > 1;
    this.operationTypeColor = this.getOperationTypeColor(this.taskEntries.first()!);
    this.operationStatusColor = this.getOperationStatusColor(this.taskEntries.first()!.status);
    this.operationStatusString = this.getOperationStatusString(this.taskEntries.first()!.status);
    this.operationTypeString = this.getOperationTypeString(this.taskEntries.first()!);
  }

  private getOperationTypeColor(taskEntry: TaskEntry): string {
    return ChipUtil.getOperationTypeColor(taskEntry);
  }

  private getOperationStatusColor(status: Status): string {
    return ChipUtil.getOperationStatusColor(status);
  }

  private getOperationStatusString(status: Status): string {
    return ChipUtil.getOperationStatusString(status);
  }

  protected getOperationTypeString(taskEntry: TaskEntry): string {
    return ChipUtil.getOperationTypeString(taskEntry);
  }

  private hoverTimeout: any = null;

  protected hoverSingleCard = false;
  protected hoverMultipleCards = false;

  onMouseEnterSingleCard() {
    if (this.isMobile) {
      return;
    }
    this.clearHoverTimeout();
    this.hoverTimeout = setTimeout(() => {
      this.hoverSingleCard = true;
      this.hoverMultipleCards = false;
      this.hoverTimeout = null;
      this.cdr.markForCheck();
    }, this.DELAY);
  }

  onMouseLeaveSingleCard(softLeave = false) {
    if (this.isMobile) {
      return;
    }
    this.clearHoverTimeout();
    if (this.hoverTimeout === null && !softLeave) {
      this.hoverSingleCard = false;
    }
  }

  onMouseEnterMultipleCards() {
    if (this.isMobile) {
      return;
    }
    this.clearHoverTimeout();
    this.hoverTimeout = setTimeout(() => {
      this.hoverMultipleCards = true;
      this.hoverSingleCard = false;
      this.cdr.markForCheck();
    }, this.DELAY);
  }

  onMouseLeaveMultipleCards(softLeave = false) {
    if (this.isMobile) {
      return;
    }
    this.clearHoverTimeout();
    if (this.hoverTimeout === null && !softLeave) {
      this.hoverMultipleCards = false;
    }
  }

  private clearHoverTimeout() {
    if (this.hoverTimeout) {
      clearTimeout(this.hoverTimeout);
      this.hoverTimeout = null;
    }
  }

  protected onClickSingleCard() {
    if (this.isMobile) {
      this.hoverSingleCard = !this.hoverSingleCard;
      this.cdr.markForCheck();
    }
  }

  protected onMultipleCards() {
    if (this.isMobile) {
      this.hoverMultipleCards = !this.hoverMultipleCards;
      this.cdr.markForCheck();
    }
  }
}
