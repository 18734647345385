import { TDateISO } from '@app/core/types/iso-date.type';
import { TaskEntry } from '@app/farm-tasks-overview/class/task-entry';
import { TaskEntryDto } from '@app/farm-tasks-overview/interfaces/task-entry';
import { memoize as memoizee } from 'lodash-es';
import { DateTime } from 'luxon';

export class DataTableUtils {
  /**
   * Formats a DateTime object to YYYY-MM-DD string
   */
  static formatDate(date: DateTime): string {
    return date.toFormat('yyyy-MM-dd');
  }

  /**
   * Checks if a date is the first date of its month in a sequence
   */
  static isFirstDateOfMonth(date: string, previousDate: string | null): boolean {
    if (!previousDate) return true;

    const currentDate = DateTime.fromISO(date);
    const prevDate = DateTime.fromISO(previousDate);

    return currentDate.month !== prevDate.month;
  }

  /**
   * Checks if a date is the last date of its month in a sequence
   */
  static isLastDateOfMonth(date: string, nextDate: string | null): boolean {
    if (!nextDate) return false;

    const currentDate = DateTime.fromISO(date);
    const nxtDate = DateTime.fromISO(nextDate);

    return currentDate.month !== nxtDate.month;
  }

  /**
   * Checks if a date is today
   */
  static isToday(date: string): boolean {
    const today = DateTime.local().startOf('day');
    const compareDate = DateTime.fromISO(date).startOf('day');
    return today.hasSame(compareDate, 'day');
  }

  /**
   * Returns the CSS class for the header based on the month (alternating colors)
   */
  static getHeaderClass(date: string, uniqueDates: string[]): string {
    // Get unique months from our visible dates, sorted chronologically
    const uniqueMonths = [...new Set(uniqueDates.map((d) => DateTime.fromISO(d).toFormat('yyyy-MM')))];
    const monthIndex = uniqueMonths.indexOf(DateTime.fromISO(date).toFormat('yyyy-MM'));
    return monthIndex % 2 === 0 ? 'odd-month' : 'even-month';
  }

  /**
   * Generates a unique drop list ID for drag and drop operations
   */
  static generateDropListId(fieldId: string, date: string): string {
    return `drop-list-${fieldId}-${date}`;
  }

  /**
   * Extracts date from a drop list ID
   */
  static getDateFromDropListId(id: string): string {
    return id.split('-').slice(3).join('-');
  }

  /**
   * Updates a task's date while preserving the original time
   */
  static updateTaskDateTime(task: TaskEntryDto, newDate: string): TDateISO {
    const originalTime = DateTime.fromISO(task.date!);
    const newDateTime = DateTime.fromISO(newDate).set({
      hour: originalTime.hour,
      minute: originalTime.minute,
      second: originalTime.second,
      millisecond: originalTime.millisecond,
    });
    return newDateTime.toISO() as TDateISO;
  }

  /**
   * Checks if adding dates after a given date would result in new dates
   */
  static wouldAddDatesAfter(currentDates: string[], date: string, numberOfDates: number): boolean {
    const startDate = DateTime.fromISO(date);

    for (let i = 1; i <= numberOfDates; i++) {
      const newDate = startDate.plus({ days: i }).startOf('day');
      const newDateStr = DataTableUtils.formatDate(newDate);

      if (!currentDates.includes(newDateStr)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Checks if adding dates before a given date would result in new dates
   */
  static wouldAddDatesBefore(currentDates: string[], date: string, numberOfDates: number): boolean {
    const startDate = DateTime.fromISO(date);

    for (let i = 1; i <= numberOfDates; i++) {
      const newDate = startDate.minus({ days: i }).startOf('day');
      const newDateStr = DataTableUtils.formatDate(newDate);

      if (!currentDates.includes(newDateStr)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Generates required dates (today and tomorrow)
   */
  static getRequiredDates(): string[] {
    const today = DateTime.local().startOf('day');
    const tomorrow = today.plus({ days: 1 });
    return [DataTableUtils.formatDate(today), DataTableUtils.formatDate(tomorrow)];
  }

  /**
   * Ensures a minimum number of dates after today exists in the date set
   * Only adds future dates if it's the current year
   */
  static ensureDaysAfterToday(dates: Set<string>, minimumDaysAfterToday: number, isCurrentYear: boolean): void {
    if (!isCurrentYear) return;

    const today = DateTime.local().startOf('day');
    // Count how many dates we have after today
    let datesAfterToday = 0;
    const sortedDates = Array.from(dates).sort((a, b) => DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis());

    for (const date of sortedDates) {
      if (DateTime.fromISO(date) > today) {
        datesAfterToday++;
      }
    }

    // Add more dates if needed
    let currentDate = today;
    while (datesAfterToday < minimumDaysAfterToday) {
      currentDate = currentDate.plus({ days: 1 });
      const dateStr = DataTableUtils.formatDate(currentDate);
      if (!dates.has(dateStr)) {
        dates.add(dateStr);
        datesAfterToday++;
      }
    }
  }

  static compareStrings(a: string | undefined | null, b: string | undefined | null): number {
    const valA = a || '';
    const valB = b || '';

    // Check if both strings have the number-number pattern
    const fieldNumberPattern = /^\d+-\d+$/;
    if (fieldNumberPattern.test(valA) && fieldNumberPattern.test(valB)) {
      const [firstA, secondA] = valA.split('-').map(Number);
      const [firstB, secondB] = valB.split('-').map(Number);

      // Compare first numbers numerically
      if (firstA !== firstB) {
        return firstA - firstB;
      }
      // If first numbers are equal, compare second numbers
      return secondA - secondB;
    }

    // Fall back to regular string comparison for non-field numbers
    return valA.localeCompare(valB);
  }

  static compareDates(a: number, b: number): number {
    return a - b;
  }

  static getEarliestTaskDate(tasks: TaskEntry[]): number {
    if (!tasks?.length) {
      return DateTime.local().plus({ years: 1 }).toMillis();
    }

    const validDates = tasks.filter((t) => t.date).map((t) => DateTime.fromISO(t.date ?? '').toMillis());

    return validDates.length ? Math.min(...validDates) : DateTime.local().plus({ years: 1 }).toMillis();
  }
}

export function memoize() {
  return function (target: object, key: string, descriptor: PropertyDescriptor): PropertyDescriptor {
    const oldFunction = descriptor.value;
    const newFunction = memoizee(oldFunction);
    descriptor.value = function (...args: unknown[]) {
      return newFunction.apply(this, args);
    };
    return descriptor;
  };
}
