import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

export const tableAnimations = {
  // Animation for table fade-in/out
  tableFade: trigger('tableFade', [
    transition(':enter', [
      style({
        opacity: 0,
        transform: 'translateY(10px)', // Slight upward movement
      }),
      animate(
        '800ms cubic-bezier(0.4, 0, 0.2, 1)',
        style({
          opacity: 1,
          transform: 'translateY(0)',
        })
      ),
    ]),
    transition(':leave', [
      style({
        opacity: 1,
        transform: 'translateY(0)',
      }),
      animate(
        '500ms cubic-bezier(0.4, 0, 0.2, 1)',
        style({
          opacity: 0,
          transform: 'translateY(-10px)', // Slight downward movement
        })
      ),
    ]),
  ]),

  rowReorder: trigger('rowReorder', [
    transition('* => *', [
      query(
        ':enter',
        [
          style({ opacity: 0, transform: 'translateY(-20px)' }),
          stagger(20, [animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))]),
        ],
        { optional: true }
      ),
      query(':leave', [stagger(20, [animate('300ms ease-in', style({ opacity: 0, transform: 'translateY(20px)' }))])], { optional: true }),
    ]),
  ]),
};
