import { Resource } from '@app/shared/access-control/resources';

export enum OperationTypeGroupEnum {
  MachineEvent = 1,
  Seeding = 2,
  Fertilizer = 3,
  PlantProtection = 4,
  Harvest = 5,
  Other = 6,
  Lime = 7,
  Unspecified = 99,
}

/**
 * Maps OperationTypeGroupEnum to Resource for Access Control.
 * @param id Operation type group id.
 * @returns Resource or array of Resources.
 */
export const mapToVraTaskResource = (id: OperationTypeGroupEnum): Resource | Resource[] | null => {
  switch (id) {
    case OperationTypeGroupEnum.MachineEvent:
      return null;
    case OperationTypeGroupEnum.Seeding:
      return 'vra_seeding';
    case OperationTypeGroupEnum.Fertilizer:
      return ['vra_fertilization_nitrogen', 'vra_fertilization_potassium'];
    case OperationTypeGroupEnum.PlantProtection:
      return ['vra_plant_protection'];
    case OperationTypeGroupEnum.Harvest:
      return null;
    case OperationTypeGroupEnum.Other:
      return null;
    case OperationTypeGroupEnum.Lime:
      return 'vra_lime';
    default:
      return null;
  }
};
