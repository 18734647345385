import { Injectable } from '@angular/core';
import { FieldEntry } from '@app/farm-tasks-overview/class/field-entry';
import { TaskEntry } from '@app/farm-tasks-overview/class/task-entry';

export interface TableRow {
  fieldEntry: FieldEntry;
  tasksByDate: { [key: string]: TaskEntry[] };
}

export interface TableState {
  readonly rows: TableRow[];
  readonly dates: string[];
}

export class LRUCache<K, V> {
  private cache = new Map<K, V>();
  private readonly maxSize: number;
  private readonly timeToLive: number; // milliseconds

  constructor(maxSize = 100, timeToLiveMinutes = 5) {
    this.maxSize = maxSize;
    this.timeToLive = timeToLiveMinutes * 60 * 1000;
  }

  get(key: K): V | undefined {
    const item = this.cache.get(key);
    if (item) {
      // Refresh item position
      this.cache.delete(key);
      this.cache.set(key, item);
    }
    return item;
  }

  set(key: K, value: V): void {
    if (this.cache.has(key)) {
      this.cache.delete(key);
    } else if (this.cache.size >= this.maxSize) {
      // Remove oldest item
      const firstKey = this.cache.keys().next().value;
      if (firstKey !== undefined) {
        this.cache.delete(firstKey);
      }
    }
    this.cache.set(key, value);

    // Set expiry
    setTimeout(() => {
      this.cache.delete(key);
    }, this.timeToLive);
  }

  clear(): void {
    this.cache.clear();
  }

  has(key: K): boolean {
    return this.cache.has(key);
  }
}

@Injectable({
  providedIn: 'root',
})
export class TableCacheService {
  private readonly dataCache = new LRUCache<string, TableState>(50, 5); // 50 items, 5 minutes TTL

  getCachedData(key: string): TableState | undefined {
    return this.dataCache.get(key);
  }

  setCachedData(key: string, data: TableState): void {
    this.dataCache.set(key, data);
  }

  generateCacheKey(filters: any, sort: any, page: number): string {
    return `${JSON.stringify(filters)}-${JSON.stringify(sort)}-${page}`;
  }
}
