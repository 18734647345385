import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { OperationTypes } from '@app/core/enums/operation-types.enum';
import { ProductEntry } from '@app/farm-tasks-overview/class/product-entry';
import { FieldEntryQuery } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.query';
import { FieldEntryStore } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.store';
import { ChipUtil } from '@app/farm-tasks-overview/util/chip-util';
import { BehaviorSubject, Subject, debounceTime, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductFilterService implements OnDestroy {
  public operationTypesAndProducts$ = this.ftoQuery.operationTypesAndProducts$;
  public selectAllproductsSubject = new BehaviorSubject<boolean>(false);
  public selectSpecificProductSubject = new BehaviorSubject<{ operationType: number; productIds: number[] | undefined } | null>(null);
  public filterProduct$ = this.ftoQuery.ProductFilter$;

  private pendingProducts = new Set<ProductSubType>();
  private productUpdates$ = new Subject<Set<ProductSubType>>();
  private destroy$ = new Subject<void>();

  constructor(
    private ftoQuery: FieldEntryQuery,
    private fb: FormBuilder,
    private ftoStore: FieldEntryStore
  ) {
    this.setupProductUpdates();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setSelectedProducts(products: ProductSubType[]): void {
    // Add new products to pending set
    products.forEach((product) => this.pendingProducts.add(product));

    // Emit the current state of pending products
    this.productUpdates$.next(this.pendingProducts);
  }

  private setupProductUpdates(): void {
    this.productUpdates$.pipe(debounceTime(1000), takeUntil(this.destroy$)).subscribe((products) => {
      // Commit the accumulated changes to the store
      this.ftoStore.store.update((state) => ({
        ...state,
        products: Array.from(products),
      }));

      // Clear pending products after commit
      this.pendingProducts.clear();
    });
  }

  public mapToMenuData(operationTypesAndProducts: Record<OperationTypes, ProductEntry[]>): ProductMenuDataItem[] {
    return Object.entries(operationTypesAndProducts).map(([operationType, productEntries]) => {
      // First, map products and create a map to identify duplicates
      const productMap = new Map<string, ProductSubType[]>();

      productEntries.forEach((productEntry) => {
        const product = {
          id: productEntry.id,
          productId: productEntry.id,
          label: productEntry.label,
        } as ProductSubType;

        const key = product.label;
        const existing = productMap.get(key) || [];
        productMap.set(key, [...existing, product]);
      });

      // Process products with modified labels for duplicates
      const processedProducts = Array.from(productMap.entries()).flatMap(([label, products]) => {
        if (products.length > 1) {
          // If multiple products with same label, modify labels
          return products.map((product, index) => ({
            ...product,
            label: `${label} (${product.id})`,
          }));
        }
        return products;
      });

      return {
        id: Number(operationType),
        type: Number(operationType) as OperationTypes,
        products: processedProducts.sort((a, b) => a.label.localeCompare(b.label)),
      } as ProductMenuDataItem;
    });
  }

  public createGroups(data: ProductMenuDataItem[]): FormGroup {
    return this.fb.group({
      anySelected: [true],
      hoveredItem: [null],
      groups: this.fb.array(
        data.map((item) => {
          return this.fb.group({
            id: [item.id],
            type: this.fb.group({
              id: [item.id],
              name: [this.getNameBasedOnLanguage(item.type)],
              type: [item.type],
              selected: [true],
              partiallySelected: [true],
              color: [ChipUtil.getOperationTypeColor(item.type)],
            }),
            products: this.fb.array(
              item.products.map((product) => {
                return this.fb.group({
                  id: [product.id],
                  name: [product.label],
                  product: [product],
                  type: [item.type],
                  selected: [true],
                });
              })
            ),
          });
        })
      ),
    });
  }

  public updateParentTypeStatus(typeGroup: FormGroup, productArray: FormArray): void {
    const subTypeStates = productArray.controls.map((control) => control.get('selected')?.value);
    const allSelected = subTypeStates.every((state) => state === true);
    const parentTypeIdOfSubTypes = productArray.controls[0]?.get('type')?.value;
    const typeIdOfType = typeGroup.get('id')?.value;
    const someSelected = subTypeStates.some((state) => state === true) && parentTypeIdOfSubTypes === typeIdOfType;

    typeGroup.patchValue(
      {
        selected: allSelected,
        partiallySelected: !allSelected && someSelected,
      },
      { emitEvent: false }
    );
  }

  getNameBasedOnLanguage(type: OperationTypes): string {
    return 'farm-tasks-overview.filter-product.operation-groups.' + type;
  }
}

export interface ProductSubType {
  id: number;
  productId: number;
  label: string;
}

export interface ProductMenuDataItem {
  id: number;
  type: OperationTypes;
  products: ProductSubType[];
  color: string;
}
