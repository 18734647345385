import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { OlCellFeatureDirective } from '@app/map/ol-cell-hover/ol-cell-feature.directive';
import { OlCellHoverComponent, OlCellOutletDirective } from '@app/map/ol-cell-hover/ol-cell-hover.component';
import { AccordionModule } from '@app/shared/accordion/accordion.module';
import { BadgeModule } from '@app/shared/badge/badge.module';
import { ButtonsModule } from '@app/shared/buttons/buttons.module';
import { CardsOrTableModule } from '@app/shared/cards-or-table/cards-or-table.module';
import { ChartWrapperModule } from '@app/shared/chart-wrapper/chart-wrapper.module';
import { ComponentSelectorComponent } from '@app/shared/component-selector/component-selector.component';
import { AppDirectiveModule } from '@app/shared/directives/app-directive.module';
import { FileUploadModule } from '@app/shared/file-upload/file-upload.module';
import { GrassPrognosisModule } from '@app/shared/grass-prognosis/grass-prognosis.module';
import { HarvestYearModule } from '@app/shared/harvest-year/harvest-year.module';
import { InputModule } from '@app/shared/input/input.module';
import { LanguagePickerModule } from '@app/shared/language-picker/language-picker.module';
import { MapCoverFlowModule } from '@app/shared/map-cover-flow/map-cover-flow.module';
import { MaterialModule } from '@app/shared/material/material.module';
import { NotificationsModule } from '@app/shared/notifications/notifications.module';
import { OpenLayersMapModule } from '@app/shared/openlayers-map/openlayers-map.module';
import { AppPipesModule } from '@app/shared/pipes/pipes.module';
import { RainChartModule } from '@app/shared/rain-chart/rain-chart.module';
import { ScaleLegendModule } from '@app/shared/scale-legend/scale-legend.module';
import { SearchFieldModule } from '@app/shared/search-field/search-field.module';
import { SearchModule } from '@app/shared/search/search.module';
import { SelectModule } from '@app/shared/select/select.module';
import { SideDrawerModule } from '@app/shared/side-drawer/side-drawer.module';
import { SpinnerModule } from '@app/shared/spinner/spinner.module';
import { TooltipModule } from '@app/shared/tooltip/tooltip.module';
import { TranslateModule } from '@app/shared/translate/translate.module';
import { WetHoursChartModule } from '@app/shared/wet-hours-chart/wet-hours-chart.module';
import { CheckboxModule } from '@fe-shared/ui-components/checkbox/checkbox.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { TextareaModule } from '@fe-shared/ui-components/textarea/textarea.module';
import { SubscribeDirective } from '@ngneat/subscribe';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { NgClickOutsideDirective, NgClickOutsideExcludeDirective } from 'ng-click-outside2';
import { AccessControlModule } from './access-control/access-control.module';
import { CellFeatureDirective } from './cell-hover/cell-feature.directive';
import { CellHoverComponent, CellOutletDirective } from './cell-hover/cell-hover.component';
import { DatePickerModule } from './date-picker/date-picker.module';
import { DebugInfoComponent } from './debug-info/debug-info.component';
import { DialogModule } from './dialog/dialog.module';
import { DownloadManagerDialogComponent } from './download-manager/download-manager.component';
import { EnviromentBranchSelectorComponent } from './enviroment-branch-selector/enviroment-branch-selector.component';
import { EnvironmentIndicatorComponent } from './environment-indicator/environment-indicator.component';
import { FeatureToggleDirective } from './feature-toggle/feature-toggle.directive';
import { FeatureTogglePipe } from './feature-toggle/feature-toggle.pipe';
import { FeedbackModule } from './feedback-side-drawer/feedback-side-drawer-content/feedback/feedback.module';
import { FeedbackSideDrawerModule } from './feedback-side-drawer/feedback-side-drawer.module';
import { FpPowerbiModule } from './fp-powerbi/fp-powerbi.module';
import { GeojsonIconComponent } from './geojson-icon/geojson-icon.component';
import { GrowthstageModule } from './growthstage/growthstage.module';
import { InfoHoverModule } from './info-hover/info-hover.module';
import { ListModule } from './list/list.module';
import { MoreInfoModule } from './more-info/more-info.module';
import { InputFormatDirective } from './pipes/inputFormat/input-format.directive';
import { MaxLengthDirective } from './pipes/maxLength/max-length.directive';
import { RadioModule } from './radio/radio.module';
import { ReminderWindowModule } from './reminder-window/reminder-window.module';
import { SeptoriaChartModule } from './septoria/septoria-chart.module';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { SkeletonDirective } from './skeleton/skeleton.directive';
import { SpinnerOverlayModule } from './spinner-overlay/spinner-overlay.module';
import { TableModule } from './table/table.module';
import { TooltipOverlayModule } from './tooltip-overlay/tooltip-overlay.module';
import { WaterBalanceModule } from './water-balance/water-balance.module';
/**
 * All shared components, directives and pipes
 */
const directives = [
  CellFeatureDirective,
  CellHoverComponent,
  CellOutletDirective,
  ComponentSelectorComponent,
  DebugInfoComponent,
  DownloadManagerDialogComponent,
  EnvironmentIndicatorComponent,
  EnviromentBranchSelectorComponent,
  GeojsonIconComponent,
  FeatureToggleDirective,
  FeatureTogglePipe,
  SkeletonComponent,
  SkeletonDirective,
  InputFormatDirective,
  MaxLengthDirective,
  OlCellHoverComponent,
  OlCellFeatureDirective,
  OlCellOutletDirective,
] as const;

/**
 * All shared modules
 */
const modules = [
  AccessControlModule,
  AccordionModule,
  AppDirectiveModule,
  MaterialModule,
  AppPipesModule,
  BadgeModule,
  ButtonsModule,
  CardsOrTableModule,
  CdkTableModule,
  ChartsModule,
  ChartsModule,
  ChartWrapperModule,
  CheckboxModule,
  NgClickOutsideDirective,
  NgClickOutsideExcludeDirective,
  CommonModule,
  DatePickerModule,
  AppDirectiveModule,
  DialogModule,
  DropDownListModule,
  FeedbackModule,
  FeedbackSideDrawerModule,
  FileUploadModule,
  FormsModule,
  FpPowerbiModule,
  GrassPrognosisModule,
  GrowthstageModule,
  HarvestYearModule,
  IconModule,
  InfoHoverModule,
  InputModule,
  LanguagePickerModule,
  ListModule,
  MapCoverFlowModule,
  MapCoverFlowModule,
  MoreInfoModule,
  MoreInfoModule,
  NotificationsModule,
  OpenLayersMapModule,
  PDFExportModule,
  RadioModule,
  RainChartModule,
  ReactiveFormsModule,
  ReminderWindowModule,
  RouterModule,
  ScaleLegendModule,
  SearchFieldModule,
  SearchModule,
  SelectModule,
  SeptoriaChartModule,
  SideDrawerModule,
  SpinnerModule,
  SpinnerModule,
  SpinnerOverlayModule,
  SubscribeDirective,
  TableModule,
  TextareaModule,
  TooltipModule,
  TooltipOverlayModule,
  TranslateModule,
  WaterBalanceModule,
  WetHoursChartModule,
] as const;

/**
 * Providers that are shared across the application.
 */
const providers: Provider[] = [
  NotificationService,
  { provide: MAT_DATE_FORMATS, useFactory: LanguageConstants.getMatDateFormat, deps: [LanguageService] },
  {
    provide: MAT_DATE_LOCALE,
    useFactory: (languageService: LanguageService) => languageService.currentLanguage.shortKey,
    deps: [LanguageService],
  },
];

/**
 * Shared module for all components, directives and pipes that are used in multiple modules.
 */
@NgModule({
  imports: [...modules],
  declarations: [...directives],
  exports: [...directives, ...modules],
  providers: [...providers],
})
export class SharedModule {}
