import { OperationTypes } from '@app/core/enums/operation-types.enum';
import { Unit } from '@app/core/interfaces/unit.type';
import { TDateISO } from '@app/core/types/iso-date.type';
import { Status } from '../enums/status.enum';
import { ProductEntryDto } from '../interfaces/product-entry';
import { TaskEntryDto } from '../interfaces/task-entry';
import { ProductEntry } from './product-entry';

export class TaskEntry {
  private _id: number;
  private _status: Status;
  private _date: TDateISO;
  private _area?: number;
  private _products: ProductEntry[];

  constructor(dto: TaskEntryDto) {
    this._id = dto.id;
    this._status = dto.status;
    this._date = dto.date;
    this._area = dto.area;
    this._products = dto.products.map((product) => new ProductEntry(product));
  }

  public get id(): number {
    return this._id;
  }

  public get types(): OperationTypes[] {
    return [...new Set(this.products.map((p) => p.type))];
  }

  public get status(): Status {
    return this._status;
  }

  public withStatus(status: Status): TaskEntry {
    return { ...this, status: status };
  }

  public get date(): TDateISO | undefined {
    return this._date;
  }

  //ONLY HERE FOR TESTING %%TODO: REMOVE
  public set date(date: TDateISO) {
    this._date = date;
  }

  public withDate(date: TDateISO): TaskEntry {
    return { ...this, date: date };
  }

  public get area(): number | undefined {
    return this._area;
  }

  public withArea(area: number): TaskEntry {
    return { ...this, area: area };
  }

  public get products(): ProductEntry[] {
    return this._products;
  }

  public withProducts(products: ProductEntry[] | ProductEntryDto[]): TaskEntry {
    const updatedProducts = products.map((product) => (product instanceof ProductEntry ? product : new ProductEntry(product)));
    var dto = this.toDto();
    dto.products = updatedProducts.map((p) => p.toDto());
    return new TaskEntry(dto);
  }

  public getProductsWithLabel(label: string): ProductEntry[] {
    const products = this.products.filter((product) => product.label === label);
    return products;
  }

  public getProductsWithUnitType(unit: Unit): ProductEntry[] {
    const products = this.products.filter((product) => product.unit === unit);
    return products;
  }

  public getProducts(): ProductEntry[] {
    const products = this.products;
    return products;
  }

  public getFirstOrderedOperationType(): OperationTypes {
    const result = this.products.find((p) => p.priority === 1)!.type;
    return result;
  }

  public getFirstOrderedProduct(): ProductEntry {
    const result = this.products.find((p) => p.priority === 1)!;
    return result;
  }

  public getFirstProductofOperationType(type: OperationTypes): ProductEntry | undefined {
    const result = this.products.filter((product) => product.type === type)!.sort((a, b) => a.priority - b.priority);

    return result[0];
  }

  public hasProduct(products: number[]): boolean {
    return this.products.some((product) => products.includes(product.id));
  }

  public moveProductsInPriorityOrder(products: number[]): TaskEntry {
    // Separate matching products and non-matching products
    const matchingProducts = this.products
      .filter((product) => products.includes(product.id))
      .sort((a, b) => products.indexOf(a.id) - products.indexOf(b.priority));

    const nonMatchingProducts = this.products.filter((product) => !products.includes(product.id));

    // Combine matched products first, followed by non-matched
    const reorderedProducts = [...matchingProducts, ...nonMatchingProducts];

    const reorderedWithPriority: ProductEntry[] = [];
    // Reassign priorities
    reorderedProducts.forEach((product, index) => {
      reorderedWithPriority.push(product.withpriority(index + 1));
    });

    // Create a new TaskEntry with updated products
    const newObj = this.withProducts(reorderedWithPriority);
    return newObj;
  }

  public toDto(): TaskEntryDto {
    return {
      id: this._id,
      status: this._status,
      date: this._date,
      area: this._area,
      products: this._products.map((product) => product.toDto()), // Assuming ProductEntry has a toDto() method
    };
  }
}
