import { Injectable } from '@angular/core';
import { FieldEntry } from '@app/farm-tasks-overview/class/field-entry';
import { ProductSubType } from '@app/farm-tasks-overview/components/product-filter/product-filter.service';
import { createStore, withProps } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { entitiesStateHistory } from '@ngneat/elf-state-history';
import { debounceTime, map } from 'rxjs';

interface LoadingProps {
  preLoading: boolean;
}

interface SortProps {
  sortType: SortType;
  sortDirection: SortDirection;
}

export interface CropFilterType {
  cropName: string;
  term: number;
}
interface CropFilterProps {
  crops: CropFilterType[];
}

interface ProductFilterProps {
  products: ProductSubType[];
}

@Injectable({ providedIn: 'root' })
export class FieldEntryStore {
  constructor() {}
  public readonly store = createStore(
    { name: 'field-entries' },
    withEntities<FieldEntry>({ initialValue: [] }),
    withProps<LoadingProps>({ preLoading: false }),
    withProps<SortProps>({ sortType: 'taskDate', sortDirection: 'asc' }),
    withProps<ProductFilterProps>({ products: [] }),
    withProps<CropFilterProps>({ crops: [] })
  );

  public readonly persist = persistState(this.store, {
    key: 'field-entries-state',
    storage: localStorageStrategy,
    source: () =>
      this.store.pipe(
        debounceTime(1000),
        map((state) => ({
          products: state.products,
          crops: state.crops,
          sortType: state.sortType,
          sortDirection: state.sortDirection,
        }))
      ),
  });

  // Track store history for undo/redo
  public readonly history = entitiesStateHistory(this.store);
}

export type SortType = 'fieldLabel' | 'taskDate' | 'crop' | 'fieldArea';
export type SortDirection = 'asc' | 'desc';
export interface SortState {
  sortType: SortType;
  sortDirection: SortDirection;
}
