import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TaskEntry } from '@app/farm-tasks-overview/class/task-entry';
import { Status } from '@app/farm-tasks-overview/enums/status.enum';
import { ChipUtil } from '@app/farm-tasks-overview/util/chip-util';

@Component({
  selector: 'app-task-details-card',
  templateUrl: './task-details-card.component.html',
  styleUrls: ['./task-details-card.component.scss'],
})
export class TaskDetailsCardComponent implements OnInit {
  @Input() public taskEntry!: TaskEntry;
  @Output() public clickedOutside = new EventEmitter();

  protected operationTypeColor = '';
  protected operationTypeString = '';
  protected operationStatusColor = '';
  protected operationStatusString = '';

  constructor() {}

  ngOnInit(): void {
    this.operationTypeColor = this.getOperationTypeColor(this.taskEntry);
    this.operationStatusColor = this.getOperationStatusColor(this.taskEntry.status);
    this.operationStatusString = this.getOperationStatusString(this.taskEntry.status);
    this.operationTypeString = this.getOperationTypeString(this.taskEntry);
  }

  private getOperationStatusString(status: Status): string {
    return ChipUtil.getOperationStatusString(status);
  }

  protected getOperationTypeString(taskEntry: TaskEntry): string {
    return ChipUtil.getOperationTypeString(taskEntry);
  }

  private getOperationTypeColor(taskEntry: TaskEntry): string {
    return ChipUtil.getOperationTypeColor(taskEntry);
  }

  private getOperationStatusColor(status: Status): string {
    return ChipUtil.getOperationStatusColor(status);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (event instanceof MouseEvent) {
      const target = event.target as HTMLElement;

      const isInsideAnyTaskDetailsCard = target.closest('app-task-details-card');

      if (!isInsideAnyTaskDetailsCard) {
        this.clickedOutside.emit();
      }
    }
  }

  @HostListener('document:touchmove', ['$event'])
  onDocumentTouchMove(): void {
    this.clickedOutside.emit();
  }

  @HostListener('document:mousemove', ['$event'])
  onDocumentMouseMove(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    const isInsideAnyTaskDetailsCard = target.closest('app-task-details-card');

    if (!isInsideAnyTaskDetailsCard) {
      this.clickedOutside.emit();
    }
  }
}
