import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { Observable } from 'rxjs';
import { FieldEntryDto } from '../interfaces/field-entry';

@Injectable({
  providedIn: 'root',
})
export class FieldEntryRepositoryService {
  constructor(
    private http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getFieldEntries(farmIds: number[], harvestYear: number): Observable<FieldEntryDto[]> {
    const url = `${this.endpoints.cmApi}/FieldEntry?farmIds=${farmIds.join(',')}&harvestYear=${harvestYear}`;
    return this.http.get<FieldEntryDto[]>(url);
  }
}
