import { Injectable } from '@angular/core';
import { FieldEntry } from '@app/farm-tasks-overview/class/field-entry';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { setEntities } from '@ngneat/elf-entities';
import { combineLatest, switchMap, tap } from 'rxjs';
import { FieldEntryRepositoryService } from '../../field-entry-repository.service';
import { FieldEntryStore, SortDirection, SortType } from './field-entry.store';

// Define the CropFilterType interface
export interface CropFilterType {
  cropName: string;
  term: number;
}

@Injectable({
  providedIn: 'root',
})
export class FieldEntryService {
  constructor(
    private _store: FieldEntryStore,
    private _harvestYearService: HarvestYearStateService,
    private _farmService: FarmStateService,
    private _fieldEntryRepository: FieldEntryRepositoryService
  ) {}
  private _subscriptions = new SubscriptionArray();

  public init() {
    this._subscriptions.unsubscribe();
    this._subscriptions.push(this.fieldEntryFetchOnFarmOrYearChange());
  }

  private fieldEntryFetchOnFarmOrYearChange() {
    return combineLatest([this._harvestYearService.harvestYear$, this._farmService.selectedFarmIds$])
      .pipe(
        switchMap(([harvestYear, farmIds]) => {
          if (!harvestYear || !farmIds || farmIds.length === 0) {
            console.warn('Harvest year or farm IDs are missing');
            return [];
          }
          return this._fieldEntryRepository.getFieldEntries(farmIds, harvestYear);
        }),
        tap((fieldEntries) => {
          const fieldEntryEntities = fieldEntries.map((f) => new FieldEntry(f));
          this._store.store.update(setEntities(fieldEntryEntities));
          this._store.store.update((state) => ({
            ...state,
            crops: Object.values(state.entities)
              .map((f) => ({ cropName: f.cropName ?? '', term: f.successionNo ?? 0 })) // Include term
              .filter((entry) => entry.cropName !== '') // Remove empty crop names
              .filter(
                (entry, index, self) => self.findIndex((e) => e.cropName === entry.cropName && e.term === entry.term) === index // Remove duplicates
              ),
          }));
        })
      )
      .subscribe({
        error: (err) => console.error('Error fetching field entries:', err),
      });
  }

  public destroy() {
    this._subscriptions.unsubscribe();
  }

  setSort(sortType: SortType, sortDirection: SortDirection) {
    this._store.store.update((state) => ({
      ...state,
      sortType,
      sortDirection,
    }));
  }

  resetCropFilter() {
    setTimeout(() => {
      this._store.store.update((state) => ({
        ...state,
        crops: Object.values(state.entities)
          .map((f) => ({ cropName: f.cropName ?? '', term: f.successionNo ?? 0 })) // Include term
          .filter((entry) => entry.cropName !== '') // Remove empty crop names
          .filter(
            (entry, index, self) => self.findIndex((e) => e.cropName === entry.cropName && e.term === entry.term) === index // Remove duplicates
          ),
      }));
    }, 200);
  }

  clearCropFilter() {
    this._store.store.update((state) => ({
      ...state,
      crops: [],
    }));
  }

  updateCropFilter(cropFilter: CropFilterType) {
    this._store.store.update((state) => {
      const cropFilters = state.crops;
      const index = cropFilters.findIndex((c) => c.cropName === cropFilter.cropName && c.term === cropFilter.term);
      if (index === -1) {
        cropFilters.push(cropFilter);
      } else {
        cropFilters.splice(index, 1);
      }
      return {
        ...state,
        crops: cropFilters,
      };
    });
  }
}
