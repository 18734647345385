<button mat-button class="products-btn" #products="matMenuTrigger" [matMenuTriggerFor]="productsMenu">
  <div class="products-btn-content">
    <span>{{ 'farm-tasks-overview.filter-product.products' | translate }}</span>
    <span class="selected-products"> {{ (selectedProduct$ | async) ?? '' | translate }}</span>
    <mat-icon class="material-symbols-outlined">{{ products.menuOpen ? 'stat_1' : 'stat_minus_1' }}</mat-icon>
  </div>
</button>
<mat-menu #productsMenu="matMenu" class="products-menu" (click)="$event.stopPropagation()">
  <ng-container *ngIf="formGroup">
    <ng-container *ngIf="!(noproducts$ | async); else noproducts">
      <ng-template [formGroup]="formGroup" matMenuContent class="tester">
        <div class="products-menu-content">
          <div class="products-layer-menu-header">
            <b>{{ 'Produkter' | translate }}</b>
            <span class="spacer"></span>
            <u
              *ngIf="formGroup.get('anySelected')?.value"
              class="products-layer-menu-hide-all"
              (click)="onClearClick(); $event.stopPropagation()"
            >
              {{ 'common.map-controls.hide-all' | translate }}
            </u>
            <u
              *ngIf="!formGroup.get('anySelected')?.value"
              class="products-layer-menu-select-all"
              (click)="onSelectAllClick(); $event.stopPropagation()"
            >
              {{ 'common.map-controls.select-all' | translate }}
            </u>
          </div>
        </div>
        <!-- Main Menu Items -->
        <ng-container *ngFor="let item of groups!.controls; let i = index">
          <button
            class="products-menu-item"
            mat-menu-item
            [matMenuTriggerFor]="subMenuTemp"
            #menuTrigger="matMenuTrigger"
            (click)="toggleTypeAndProduct(item.get('type')?.value, item.get('products')?.value, $event, menuTrigger)"
          >
            <div class="products-menu-item-selection">
              <mat-checkbox
                class="products-menu-item-selection-checkbox"
                [checked]="item.get('type')?.get('selected')?.value"
                [indeterminate]="item.get('type')?.get('partiallySelected')?.value"
              >
                <span class="circle-icon" [ngStyle]="{ 'background-color': item.get('type')?.get('color')?.value }"></span>
                <span>{{ item.get('type')?.get('name')?.value | translate }}</span>
              </mat-checkbox>
              <mat-icon class="material-symbols-outlined right-arrow" (click)="foldOutProducts($event, menuTrigger)">
                chevron_right
              </mat-icon>
            </div>
          </button>

          <!-- Submenu for the products -->
          <mat-menu #subMenuTemp="matMenu" class="products-submenu">
            <ng-container *ngFor="let product of getProductFormArray(item)">
              <button class="products-menu-item" mat-menu-item (click)="toggleProduct(product.value, $event)">
                <div class="products-menu-item-selection">
                  <mat-checkbox [checked]="product.get('selected')?.value">
                    <span>{{ product.get('name')?.value }}</span>
                  </mat-checkbox>
                </div>
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-menu>
<ng-template #noproducts matMenuContent>
  <div class="no-products">
    <mat-icon class="material-symbols-outlined">info</mat-icon>
    <span>{{ 'farm-tasks-overview.filter-product.no-products' | translate }}</span>
  </div>
</ng-template>
