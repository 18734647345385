import { OperationTypes } from '@app/core/enums/operation-types.enum';
import { TaskEntry } from '../class/task-entry';
import { Status } from '../enums/status.enum';

export class ChipUtil {
  public static getOperationTypeColor(taskEntry: TaskEntry | OperationTypes): string {
    const type = taskEntry instanceof TaskEntry ? taskEntry.getFirstOrderedOperationType() : taskEntry;
    switch (type) {
      case OperationTypes.Seed:
        return '#ABF894';
        break;
      case OperationTypes.Fertilizer:
      case OperationTypes.Fertilization:
      case OperationTypes.OwnFertilization:
        return '#00EFC6';
        break;
      case OperationTypes.LivestockManure:
        return '#D8A475';
        break;
      case OperationTypes.Weed:
        return '#FCF0AB';
        break;
      case OperationTypes.Pests:
        return '#FF8181';
        break;
      case OperationTypes.Diseases:
        return '#FC8906';
        break;
      case OperationTypes.GrowthRegulation:
        return '#FFDD1A';
        break;
      case OperationTypes.Yield:
        return '#D88CFF';
        break;
      case OperationTypes.Other:
        return '#88DEE4';
        break;
      case OperationTypes.Machine:
      case OperationTypes.OtherMachine:
        return '#60B2FF';
        break;
      default:
        return '#BDBDBD';
        break;
    }
  }

  public static getOperationStatusColor(status: Status): string {
    switch (status) {
      case Status.EXECUTED:
        return '#28a513';
        break;
      case Status.PLANNED:
        return '#2361FF';
        break;
      case Status.EXPIRED:
        return '#ffffff';
        break;
      case Status.PAST_DUE:
        return '#ffffff';
        break;
    }
  }

  public static getOperationStatusString(status: Status): string {
    switch (status) {
      case Status.EXECUTED:
        return 'main.overview.task-card.status.executed';
        break;
      case Status.PLANNED:
        return 'main.overview.task-card.status.planned';
        break;
      case Status.EXPIRED:
        return '';
        break;
      case Status.PAST_DUE:
        return '';
        break;
    }
  }

  public static getOperationTypeString(taskEntry: TaskEntry): string {
    return taskEntry.getFirstOrderedProduct()?.label ?? '-';
  }
}
