import { ElementRef, Injectable } from '@angular/core';
import { Colors } from '@app/core/enums/colors.enum';
import { PDFLineItem, PdfContentAlignment } from '@app/core/enums/pdf.enums';
import { Farm } from '@app/core/interfaces/farm.interface';
import { LanguageService } from '@app/core/language/language.service';
import { OlMapComponent } from '@app/map/ol-map/ol-map.component';
import { OpenLayersMapComponent } from '@app/shared/openlayers-map/openlayers-map.component';
import { Group, Path, drawDOM, exportImage, exportPDF, geometry, Image as kendoImage, Text as kendoText } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import { BehaviorSubject, Observable } from 'rxjs';

// PDF
const { Point } = geometry;

@Injectable({
  providedIn: 'root',
})
export class MapPdfService {
  private readonly pdfOpacityTransparent = 0.9;
  private readonly pdfOpacityNotTransparent = 1;
  private readonly pdfOpacityFullTransparent = 0;
  private readonly pdfContentSpacing = 9;
  private readonly pdfLineSpacing = 3;
  private readonly pdfFont = '10px LFPressSans'; // uses local LFPressSans.ttf file, since is required for unicode characters to work in pdf
  private isCreatingPDF = new BehaviorSubject<boolean>(false);

  public get isCreatingPDF$(): Observable<boolean> {
    return this.isCreatingPDF.asObservable();
  }

  constructor(private languageService: LanguageService) {}

  // #region PDF
  // This is first shot at making pdf from maps in CM.
  // In this version there are several hard-coded things like paper size, file naming, etc.
  // In the future when decisions about how pdf generation of maps should be used and handled in the user interface,
  // there will be things that need to be re-factored.

  // See
  // http://docs.telerik.com/kendo-ui/framework/drawing/drawing-dom#dimensions-and-css-units-for-pdf-output

  // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  private mm(val) {
    return val * 2.8347;
  }

  private getHeaderLogoImage(): Group {
    const imageRect = new geometry.Rect([this.pdfContentSpacing * 2, this.pdfContentSpacing * 2], [150, 29]);
    const image = new kendoImage('assets/images/logos/cm-full-logo.svg', imageRect);
    const group = new Group();
    group.append(image);

    return group;
  }

  private getFarmInfoText(x: number, y: number, farm: Farm, harvestYear: number): Group {
    let initY = y;
    const farmInfoGroup = new Group();

    const farmInfoLinesColumn1: PDFLineItem[] = [
      { text: this.languageService.getText('pdf.farm'), hideLine: false, addLine: true },
      { text: this.languageService.getText('pdf.address'), hideLine: false, addLine: true },
      { text: 'hide:', hideLine: true, addLine: !!farm.address2 },
      { text: 'hide:', hideLine: true, addLine: !!farm.address3 },
      { text: 'hide', hideLine: true, addLine: !!farm.postalcode },
      { text: this.languageService.getText('pdf.cvr'), hideLine: false, addLine: true },
      { text: this.languageService.getText('pdf.harvestYear'), hideLine: false, addLine: true },
    ];

    const farmInfoLinesColumn2: PDFLineItem[] = [
      { text: farm.name.trim(), hideLine: false, addLine: true },
      {
        text: farm.address1 && farm.address1.length > 0 ? farm.address1.trim() : 'hide',
        hideLine: !farm.address1 || farm.address1.length <= 0,
        addLine: true,
      },
      { text: farm.address2, hideLine: false, addLine: !!farm.address2 },
      { text: farm.address3, hideLine: false, addLine: !!farm.address3 },
      {
        text: farm.postalcode + ' ' + farm.postalDistrict,
        hideLine: false,
        addLine: !!farm.postalcode,
      },
      { text: farm.cvr ?? 'hide', hideLine: !farm.cvr, addLine: true },
      { text: harvestYear.toString(), hideLine: false, addLine: true },
    ];

    farmInfoLinesColumn1.forEach((col1) => {
      if (col1.addLine) {
        let lineOpacity = this.pdfOpacityNotTransparent;
        if (col1.hideLine === true) {
          lineOpacity = this.pdfOpacityFullTransparent;
        }

        const textCol1 = new kendoText(col1.text, new Point(x, y), { font: this.pdfFont, opacity: lineOpacity });
        farmInfoGroup.append(textCol1);
        y += textCol1.bbox().height() + this.pdfLineSpacing;
      }
    });

    const col1Width = farmInfoGroup.bbox().width();

    farmInfoLinesColumn2.forEach((col2) => {
      if (col2.addLine) {
        let lineOpacity = this.pdfOpacityNotTransparent;
        if (col2.hideLine === true) {
          lineOpacity = this.pdfOpacityFullTransparent;
        }
        const textCol2 = new kendoText(col2.text, new Point(x + col1Width + this.pdfContentSpacing, initY), {
          font: this.pdfFont,
          opacity: lineOpacity,
        });
        farmInfoGroup.append(textCol2);
        initY += textCol2.bbox().height() + this.pdfLineSpacing;
      }
    });

    return farmInfoGroup;
  }

  private getFarmsInfoText(x: number, y: number, farms: Farm[], harvestYear: number): Group {
    const farmsInfoGroup = new Group();
    const textCol1Farm = new kendoText(this.languageService.getText('pdf.farms'), new Point(x, y), { font: this.pdfFont });
    const textCol1HarvestYear = new kendoText(this.languageService.getText('pdf.harvestYear'), new Point(x, y), { font: this.pdfFont });
    let textWidth: number;

    if (textCol1Farm.bbox().width() > textCol1HarvestYear.bbox().width()) {
      textWidth = textCol1Farm.bbox().width();
    } else {
      textWidth = textCol1HarvestYear.bbox().width();
    }

    farmsInfoGroup.append(textCol1Farm);

    farms.forEach((farm) => {
      const text = new kendoText(farm.name.trim(), new Point(x + textWidth + this.pdfContentSpacing, y), { font: this.pdfFont });
      farmsInfoGroup.append(text);
      y += text.bbox().height() + this.pdfLineSpacing;
    });

    textCol1HarvestYear.position(new Point(x, y + this.pdfLineSpacing));

    const textCol2HarvestYear = new kendoText(
      harvestYear.toString(),
      new Point(x + textWidth + this.pdfContentSpacing, y + this.pdfLineSpacing),
      {
        font: this.pdfFont,
      }
    );

    farmsInfoGroup.append(textCol1HarvestYear, textCol2HarvestYear);
    return farmsInfoGroup;
  }

  private getFarmInfoBoxImage(farms: Farm[], harvestYear: number): Group {
    let farmInfoText: Group;
    let frameWidth: number;
    const group = new Group();
    const groupHeader = new Group();
    const framePath = new Path();

    // Add header logo
    groupHeader.append(this.getHeaderLogoImage());

    // Add text
    if (farms.length > 1) {
      farmInfoText = this.getFarmsInfoText(
        this.pdfContentSpacing * 2,
        groupHeader.bbox().height() + this.pdfContentSpacing * 3,
        farms,
        harvestYear
      );
    } else {
      farmInfoText = this.getFarmInfoText(
        this.pdfContentSpacing * 2,
        groupHeader.bbox().height() + this.pdfContentSpacing * 3,
        farms[0],
        harvestYear
      );
    }

    if (groupHeader.bbox().width() > farmInfoText.bbox().width()) {
      frameWidth = groupHeader.bbox().width();
    } else {
      frameWidth = farmInfoText.bbox().width();
    }

    // Add background frame
    framePath.fill(Colors.Gray5, this.pdfOpacityTransparent);
    framePath.stroke('', 0);
    framePath
      .moveTo(this.pdfContentSpacing, this.pdfContentSpacing)
      .lineTo(frameWidth + this.pdfContentSpacing * 4, this.pdfContentSpacing)
      .lineTo(
        frameWidth + this.pdfContentSpacing * 4,
        farmInfoText.bbox().height() + groupHeader.bbox().height() + this.pdfContentSpacing * 4
      )
      .lineTo(this.pdfContentSpacing, farmInfoText.bbox().height() + groupHeader.bbox().height() + this.pdfContentSpacing * 4)
      .close();

    group.append(framePath, groupHeader, farmInfoText);

    return group;
  }

  private getElementAsImage(
    paperHeight: number,
    paperWidth: number,
    className: string,
    pdfContentAlignment: PdfContentAlignment
  ): Observable<any> {
    const drawDomElement = document.getElementsByClassName(className)[0] as HTMLElement;
    const elementScalingA4Landscape = 0.7;

    return new Observable((observer) => {
      if (drawDomElement) {
        const currentElementProps = drawDomElement.cloneNode(true) as HTMLElement;
        // Make sure is visible before drawing
        drawDomElement.style.visibility = 'visible';
        drawDomElement.style.opacity = this.pdfOpacityTransparent.toString();

        drawDOM(drawDomElement)
          .then((group: Group) => {
            // Reset visibility
            drawDomElement.style.opacity = currentElementProps.style.opacity;
            drawDomElement.style.visibility = currentElementProps.style.visibility;
            return exportImage(group);
          })
          .then((dataUri) => {
            let origin: number[];
            const imageSize = new Image();
            imageSize.src = dataUri;
            imageSize.onload = () => {
              switch (pdfContentAlignment) {
                case PdfContentAlignment.TopLeft:
                  origin = [this.pdfContentSpacing, this.pdfContentSpacing];
                  break;
                case PdfContentAlignment.TopRight:
                  origin = [paperWidth - imageSize.width * elementScalingA4Landscape - this.pdfContentSpacing, this.pdfContentSpacing];
                  break;
                case PdfContentAlignment.BottomLeft:
                  origin = [this.pdfContentSpacing, paperHeight - imageSize.height * elementScalingA4Landscape - this.pdfContentSpacing];
                  break;
                case PdfContentAlignment.BottomRight:
                  origin = [
                    paperWidth - imageSize.width * elementScalingA4Landscape - this.pdfContentSpacing,
                    paperHeight - imageSize.height * elementScalingA4Landscape - this.pdfContentSpacing,
                  ];
                  break;
              }

              const imageLegendRect = new geometry.Rect(origin, [
                imageSize.width * elementScalingA4Landscape,
                imageSize.height * elementScalingA4Landscape,
              ]);
              observer.next(new kendoImage(dataUri, imageLegendRect));
            };
          })
          .catch((err) => console.warn(err));
      } else {
        observer.next(null);
      }
    });
  }

  public generatePdf(
    map: OpenLayersMapComponent | OlMapComponent,
    hostElement: ElementRef,
    currentFarms: Farm[],
    harvestYear: number,
    isA4 = false
  ) {
    this.isCreatingPDF.next(true);
    let paperWidth: number;
    let paperHeight: number;
    switch (isA4) {
      case true:
        // Define A4 paper size
        paperWidth = this.mm(297);
        paperHeight = this.mm(210);
        break;
      case false:
        // Define A3 paper size
        paperWidth = this.mm(420);
        paperHeight = this.mm(297);
        break;
    }

    // Canvas to be drawn on when preparing pdf
    const mapPrintCanvas = document.createElement('canvas');
    mapPrintCanvas.width = paperWidth;
    mapPrintCanvas.height = paperHeight;
    // Set current map size
    const size = map.getMap().getSize();
    const viewResolution = map.getMap().getView().getResolution();
    // Set print size and scaling
    const printSize = [paperWidth, paperHeight];

    const scaling = Math.min(paperWidth / size![0], paperHeight / size![1]);
    // Set size and scale for print
    map.getMap().setSize(printSize);
    map
      .getMap()
      .getView()

      .setResolution((viewResolution ?? 0) / scaling);

    // When rendered export to pdf
    map.getMap().once('postrender', () => {
      const mapRect = new geometry.Rect([0, 0], printSize);
      const drawingElementsGroup = new Group();
      setTimeout(() => {
        // Join canvases and draw a print canvas
        const mapContext = mapPrintCanvas.getContext('2d');
        const canvasElements = hostElement.nativeElement.querySelectorAll('.ol-layers canvas');
        canvasElements.forEach((canvas: HTMLImageElement) => {
          if (canvas.width > 0) {
            mapContext?.drawImage(canvas, 0, 0, mapPrintCanvas.width, mapPrintCanvas.height);
          }
        });

        // Reset to original map size
        map.getMap().setSize(size);
        map.getMap().getView().setResolution(viewResolution);

        // Append map drawing element
        drawingElementsGroup.append(new kendoImage(mapPrintCanvas.toDataURL('image/jpeg', 1.0), mapRect));
        // Append Farm info
        drawingElementsGroup.append(this.getFarmInfoBoxImage(currentFarms, harvestYear));

        // Append Legend if any
        this.getElementAsImage(paperHeight, paperWidth, 'pdfLegendOpen', PdfContentAlignment.TopRight).subscribe((legend) => {
          if (legend) {
            drawingElementsGroup.append(legend);
          }
          // Append additional content if any
          this.getElementAsImage(paperHeight, paperWidth, 'pdfAdditionalContent', PdfContentAlignment.BottomLeft).subscribe(
            (additional) => {
              if (additional) {
                drawingElementsGroup.append(additional);
              }
              // Forces font unicode awailability when exporting to PDF
              this.getElementAsImage(paperHeight, paperWidth, 'pdfForceUnicodeUsage', PdfContentAlignment.TopLeft).subscribe(
                (forceUnicodeUsage) => {
                  if (forceUnicodeUsage) {
                    drawingElementsGroup.append(forceUnicodeUsage);
                  }
                  // Export
                  exportPDF(drawingElementsGroup, {
                    paperSize: isA4 ? 'A4' : 'A3',
                    landscape: true,
                  })
                    .then((data) => {
                      saveAs(data, 'MapExport.pdf');
                    })
                    .finally(() => {
                      this.isCreatingPDF.next(false);
                    })
                    .catch((err) => console.warn(err));
                }
              );
            }
          );
        });
      });
    });
  }
  // #endregion PDF
}
