<button
  mat-button
  class="year-selector"
  #yearSelector="matMenuTrigger"
  [disabled]="disabled"
  [matMenuTriggerFor]="yearMenu"
  [matMenuTriggerData]="menuData"
  (click)="onAutoScrollToCurrentYear()"
  [ngClass]="{ 'no-buttom-radius': yearSelector.menuOpen }"
>
  <span
    >{{ 'common.harvestYear' | translate }}: <b>{{ harvestYear$ | async }}</b></span
  >
  <mat-icon iconPositionEnd class="material-symbols-outlined">expand_more</mat-icon>
</button>

<mat-menu #yearMenu="matMenu" class="dropdown-menu">
  <ng-template matMenuContent let-menuWidth="menuWidth">
    <button [style.width.px]="menuWidth" mat-menu-item *ngFor="let year of years" (click)="selectYear(year)">
      {{ year }}
    </button>
  </ng-template>
</mat-menu>