import { Injectable } from '@angular/core';
import { latest } from '@app/shared/constants/rxjs-constants';
import { selectAllEntities } from '@ngneat/elf-entities';
import { combineLatest, distinctUntilChanged, first, map, ReplaySubject, shareReplay } from 'rxjs';
import { LayerId, OlLayerStore } from './layer.store';

@Injectable({ providedIn: 'root' })
export class OlLayerQuery {
  constructor(private _layerStore: OlLayerStore) {}

  private readonly _store = this._layerStore.store;
  private readonly _storeData$ = this._store.pipe(selectAllEntities(), distinctUntilChanged(), shareReplay(latest));
  public readonly activePage$ = this._layerStore.activePageSubject.asObservable();

  private _reRenderSubject = new ReplaySubject<void>(1);
  public reRender$ = this._reRenderSubject.asObservable();

  /** Returns all layers. */
  public get mapLayers$() {
    return this._storeData$;
  }

  public get mapLayersByActivePage$() {
    return combineLatest([this._storeData$, this.activePage$]).pipe(
      map(([layers, activePage]) => layers.filter((layer) => layer.allowedPages.includes(activePage) || layer.allowedPages.includes('all')))
    );
  }

  /** Returns the layers that are allowed on the active page. */
  public get mapLayersByActivePageUnique$() {
    return this.mapLayersByActivePage$.pipe(
      map((layers) => layers.filter((layer) => layer.enabled)),
      distinctUntilChanged((a, b) => a.length === b.length && a.every((layer, index) => layer === b[index]))
    );
  }

  public get orderedMapLayersByActivePage$() {
    return this.mapLayersByActivePage$.pipe(map((layers) => layers.sort((a, b) => b.order - a.order)));
  }

  public get orderedMapLayersUniqueByActivePage$() {
    return this.mapLayersByActivePageUnique$.pipe(map((layers) => layers.sort((a, b) => b.order - a.order)));
  }

  public get generalLayers$() {
    return this._storeData$.pipe(
      first(),
      map((layers) => layers.filter((layer) => layer.allowedPages.includes('all') && layer.group === 'generel'))
    );
  }

  public get enabledGenerelLayerCount$() {
    return this.generalLayers$.pipe(map((layers) => layers.filter((layer) => layer.enabled).length));
  }

  public get generalAndOverlayLayers$() {
    return this._storeData$.pipe(
      first(),
      map((layers) =>
        layers.filter((layer) => (layer.allowedPages.includes('all') && layer.group === 'generel') || layer.group === 'overlay')
      )
    );
  }

  public nextReRenderSubject() {
    this._reRenderSubject.next();
  }

  /** Returns the layer with the specified ID. */
  public getLayerById$(id: LayerId) {
    return this._storeData$.pipe(
      first(),
      map((layers) => layers.find((layer) => layer.id === id))
    );
  }

  /** Returns the highest Z-index of all layers. */
  public get highestZIndex$() {
    return this._storeData$.pipe(
      first(),
      map((layers) => {
        const zIndexes = layers.map((layer) => layer.order);
        return Math.max(...zIndexes);
      })
    );
  }
}
