<mat-form-field class="custom-select-sort" appearance="outline">
  <mat-select [value]="currentValue$ | async" #selectMenu>
    <mat-option *ngFor="let option of sortOptions" [value]="option.value" (click)="onSortChange(option.value)">
      {{ option.labelKey | translate }}
      <span class="sort-indicator">
        {{ (localSortState$ | async)?.sortType === option.value ? ((localSortState$ | async)?.sortDirection === 'asc' ? '↑' : '↓') : '' }}
      </span>
    </mat-option>
  </mat-select>
</mat-form-field>
