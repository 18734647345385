<mat-form-field [ngStyle]="{ width: width }">
  <span *ngIf="!!customPrefix && !noPrefix" matPrefix>
    <ng-content select="app-select-prefix"></ng-content>
  </span>
  <mat-label>{{ (placeholderText$ | async) ?? '' }}</mat-label>
  <mat-select
    [disabled]="disabled"
    [multiple]="multiple"
    [placeholder]="(placeholderText$ | async) ?? ''"
    [panelClass]="panelClass"
    [ngModel]="selected"
    [compareWith]="compareWith"
    (selectionChange)="onSelected($event)"
    (blur)="onBlur($event)"
  >
    <mat-select-trigger *ngIf="!!customTrigger">
      <ng-content select="app-select-trigger"></ng-content>
    </mat-select-trigger>

    <mat-option *ngFor="let option of options" [value]="option.value" [disabled]="option.disabled" [matTooltip]="option.tooltip ?? ''">
      <ng-template *ngIf="option.templateRef" [ngTemplateOutlet]="option.templateRef"></ng-template>
    </mat-option>

    <mat-optgroup *ngFor="let group of optionsGroups" [label]="group.label ?? ''">
      <mat-option *ngFor="let option of group.options" [value]="option.value" [disabled]="option.disabled">
        <ng-template *ngIf="option.templateRef" [ngTemplateOutlet]="option.templateRef"></ng-template>
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
<mat-error *ngIf="error">{{ error }}</mat-error>
