import { Injectable } from '@angular/core';
import { Crop } from '@app/core/interfaces/crop.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { selectAllEntities, upsertEntities } from '@ngneat/elf-entities';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { map, Observable, withLatestFrom } from 'rxjs';
import { CropFilterStore, FilterCrop } from './crop-filter.store';

@Injectable({
  providedIn: 'root',
})
export class CropFilterService {
  constructor(
    private _farmStateService: FarmStateService,
    private _cropFilterStore: CropFilterStore,
    private _translateService: TranslateService
  ) {}

  public allCrops$ = this._farmStateService.fields$.pipe(
    withLatestFrom(this.getDisabledCrops()),
    withLatestFrom(this._farmStateService.fieldFeatures$),
    map(([[fields, disabledCrops], fieldFeatures]) => {
      const crops = fields?.map((field) => field.crops).flat();

      //uniqBy handles "samle"-marker, as they from endpoint result in 2 fields
      const cropAreas = fieldFeatures?.cropsArea ?? this.getCropAreas(_.uniqBy(fields, 'number') ?? []);
      const disabledCropIds = disabledCrops.map((dc) => dc.id);

      const groupedCrops = _.groupBy(crops, (crop) => `${crop.cropNormNumber}_${crop.successionNo}`);

      const processedCrops = Object.values(groupedCrops).map((cropGroup) => {
        const firstCrop = cropGroup[0];
        const fieldIds = cropGroup.map((crop) => crop.fieldId);

        const processedCrop = `${firstCrop.cropNormNumber}_${firstCrop.successionNo}`;
        return {
          id: `${firstCrop.cropNormNumber}_${firstCrop.successionNo}`,
          fieldIds: _.uniq(fieldIds),
          color: firstCrop.cropColor,
          name: firstCrop.cropName !== '' ? firstCrop.cropName : this._translateService.instant('main.fieldmap.crops.noCrop'),
          totalArea: cropAreas[processedCrop] ?? 0,
          succession: firstCrop.successionNo,
          enabled: !disabledCropIds.includes(processedCrop),
        } as FilterCrop;
      });

      // Sort by total area in descending order
      const sortedCrops = _.orderBy(processedCrops, ['totalArea'], ['desc']);
      return sortedCrops;
    })
  );

  public updateCropFilter(crop: FilterCrop) {
    this._cropFilterStore.store.update(upsertEntities(crop));
  }
  public updateCropFilters(crops: FilterCrop[]) {
    this._cropFilterStore.store.update(upsertEntities(crops));
  }

  public resetCropFilter() {
    this._cropFilterStore.store.reset();
  }

  public getDisabledCrops() {
    return this._cropFilterStore.store.pipe(
      selectAllEntities(),
      map((crops) => crops.filter((crop) => !crop.enabled))
    );
  }

  public getAllCropsFiltered() {
    return this._cropFilterStore.store.pipe(selectAllEntities());
  }

  public getCropAreas(fields: Field[]) {
    return fields.reduce(
      (acc, field) => {
        field.crops.forEach((crop) => {
          const cropKey = `${crop.cropNormNumber}_${crop.successionNo}`; // Key based on cropName and successionNo
          const area = parseFloat(crop.area) || 0;

          if (!acc[cropKey]) {
            acc[cropKey] = 0;
          }
          acc[cropKey] += area;
        });
        return acc;
      },
      {} as Record<string, number>
    );
  }
}

export function applyCropFilter() {
  return (source$: Observable<[any, any]>): Observable<{ fieldFeatures: any[]; extent: any }> => {
    return source$.pipe(
      map(([fieldFeatures, cropFilter]) => {
        // Apply crop filter logic
        const filteredFieldFeatures = fieldFeatures!.fieldFeatures.filter((ff: any) => {
          // Extract crops from fieldFeature
          const fieldCrops = ff.field?.crops;

          if (!fieldCrops || fieldCrops.length === 0) return true; // No crops to filter, keep the fieldFeature

          // If cropFilter is empty, we don't filter out any fieldFeatures
          if (!cropFilter || cropFilter.length === 0) {
            return true; // No filter to apply, keep the fieldFeature
          }

          // Check if all crops are disabled (i.e., all match disabled crops in cropFilter)
          const areAllCropsDisabled = fieldCrops.every((crop: Crop) => {
            return cropFilter.some((cf: FilterCrop) => cf.id === crop.cropNormNumber + '_' + crop.successionNo && !cf.enabled);
          });

          // Keep the fieldFeature only if not all crops are disabled
          return !areAllCropsDisabled;
        });

        return {
          fieldFeatures: filteredFieldFeatures,
          extent: fieldFeatures!.extent,
        };
      })
    );
  };
}
