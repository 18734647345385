import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Observable } from 'rxjs';
import { ExecutedTask } from '../../executed-task.class';

@Injectable({
  providedIn: 'root',
})
export class AsAppliedTaskDetailsRepositoryService {
  private readonly TYPE_SHAPEFILE = 'shapefile';

  constructor(
    public httpClient: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public downloadShapeFiles(farmId: number, harvestYear: number | undefined | null, tasks: number[]): Observable<Blob | null> {
    const options = {
      withCredentials: true,
      responseType: 'blob',
    };

    return this.httpClient.post<Blob, Array<number>>(
      `${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/executed/tasks/export/${this.TYPE_SHAPEFILE}`,
      tasks,
      options
    );
  }

  public updateExecutedTask(executedTask: ExecutedTask, harvestYear?: number, taskId?: number, operationTypeGroupId?: number) {
    const { farmId, id } = executedTask;

    return this.httpClient.put<ExecutedTask, Partial<ExecutedTask>>(
      `${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/executed/tasks/${id}`,
      { taskId, operationTypeGroupId }
    );
  }

  public updateExecutedTasks(executedTasks: ExecutedTask[], harvestYear?: number, taskId?: number, operationTypeGroupId?: number) {
    const farmId = executedTasks.first()?.farmId;

    return this.httpClient.post<ExecutedTask, any>(`${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/executed/tasks/combine`, {
      executedTaskIds: executedTasks.map((task) => task.id),
      taskId: taskId,
      operationTypeGroupId: operationTypeGroupId,
    });
  }
}
