<div class="details-wrap">
  <div class="as-applied-details-title">
    <div class="title">{{ 'main.asApplied.taskDetails.title' | translate }}</div>
    <app-tooltip-overlay size="sm" [tooltipTemplate]="tooltip">
      <app-icon icon="info_outline" class="info-hover"> </app-icon>
    </app-tooltip-overlay>
    <ng-template #tooltip>
      <div class="tooltip-wrapper">
        <h3>{{ 'main.asApplied.tooltip.title' | translate }}</h3>
        <p>{{ 'main.asApplied.tooltip.text' | translate }}</p>
      </div>
    </ng-template>
  </div>
  <app-as-applied-task-info [appliedTasks]="appliedTasks" class="pdfAdditionalContent"> </app-as-applied-task-info>
</div>

<ng-container *ngIf="appliedTasks!.length > 1 && !linkToTaskMode">
  <!--- here goes list-->
  <app-as-applied-task-log-list
    [form]="logSelectForm"
    [field]="appliedTasks!.first()?.executedTask?.fieldName"
    [date]="appliedTasks!.first()?.executedTask?.date"
  ></app-as-applied-task-log-list>
</ng-container>

<ng-container *ngIf="!linkToTaskMode; else linkToTask">
  <!--  LINK TO TASK BUTTON   -->
  <div class="action-button-wrapper" *ngIf="appliedTasks!.first()!.executedTask.operationTypeGroupId === 99">
    <app-tooltip
      class="tooltip"
      *ngIf="!appliedTasks!.first()!.executedTask.featureId; else hasField"
      [tooltipText]="'main.asApplied.taskDetails.executedTask.noField' | translate"
      [isOneLine]="true"
    >
      <button mat-button disabled="true">
        <ng-container>
          <div class="button-content">
            <mat-icon class="material-symbols-outlined">add_link</mat-icon>
            <span>{{ 'main.asApplied.taskDetails.executedTask.linkToTask' | translate }}</span>
          </div>
        </ng-container>
      </button>
    </app-tooltip>

    <ng-template #hasField>
      <app-tooltip
        class="tooltip"
        *ngIf="!(taskSuggestions$ | async)?.length; else noTooltip"
        [tooltipText]="'main.asApplied.taskDetails.executedTask.noTask' | translate"
        [isOneLine]="true"
      >
        <button mat-button (click)="linkToTaskMode = true" [disabled]="!(anySelected$ | async) || !(taskSuggestions$ | async)?.length">
          <ng-container>
            <div class="button-content">
              <mat-icon class="material-symbols-outlined">add_link</mat-icon>
              <span>{{ 'main.asApplied.taskDetails.executedTask.linkToTask' | translate }}</span>
            </div>
          </ng-container>
        </button>
      </app-tooltip>
    </ng-template>

    <ng-template #noTooltip>
      <button mat-button (click)="linkToTaskMode = true" [disabled]="!(anySelected$ | async)">
        <ng-container>
          <div class="button-content">
            <mat-icon class="material-symbols-outlined">add_link</mat-icon>
            <span>{{ 'main.asApplied.taskDetails.executedTask.linkToTask' | translate }}</span>
          </div>
        </ng-container>
      </button>
    </ng-template>
  </div>

  <!-- ! Outcommented. See https://segesinnovation.atlassian.net/browse/CMA-10688  -->
  <!-- <div class="action-button-wrapper">
    <button mat-button (click)="AddAdditionalLogs()" [disabled]="possibleAdditionLogs?.length === 0">
      <ng-container>
        <div class="button-content">
          <mat-icon class="material-symbols-outlined">playlist_add</mat-icon>
          <span>{{ 'main.asApplied.taskDetails.addAdditionalFiles' | translate }}</span>
        </div>
      </ng-container>
    </button>
  </div> -->
  <div class="action-button-wrapper">
    <button mat-button (click)="downloadShapeFiles()" [disabled]="!(anySelected$ | async)">
      <ng-container>
        <div class="button-content">
          <mat-icon class="material-symbols-outlined">download</mat-icon>
          <span>{{
            (multipleLogs ? 'main.asApplied.taskDetails.downLoadShapeFiles' : 'main.asApplied.taskDetails.downLoadShapeFile') | translate
          }}</span>
        </div>
      </ng-container>
    </button>
  </div>
  <div class="action-button-wrapper">
    <button mat-button (click)="deleteAsApplied()" [disabled]="!(anySelected$ | async)">
      <ng-container>
        <div class="button-content">
          <mat-icon class="material-symbols-outlined">delete</mat-icon>
          <span class="mat-button-wrapper">{{
            (multipleLogs ? 'main.asApplied.taskDetails.deleteAsApplieds' : 'main.asApplied.taskDetails.deleteAsApplied') | translate
          }}</span>
        </div>
      </ng-container>
    </button>
  </div>
</ng-container>

<ng-template #linkToTask>
  <app-as-applied-link-to-task [executedTasks]="executedTasks" [taskSuggestions$]="taskSuggestions$"></app-as-applied-link-to-task>
</ng-template>

<app-ol-cell-hover [mapLayerId]="(mapLayerId$ | async)!">
  <app-as-applied-tooltip *olCellFeature="let feature" [feature]="feature"></app-as-applied-tooltip>
</app-ol-cell-hover>
